.build-analytics-container {
  padding: 20px;
}
.analytics-query-container {
  .margin {
    margin-right: 10px;
  }
  .form-item {
    margin-bottom: 20px;
  }
}
.anaytics-query-table {
  .query-table {
    margin-top: 20px;
  }
}
.anaytics-report-edit {
  background: white;
  padding: 24px 36px;
  & .ant-row {
    margin: 12px 0px;
    & .required-asterisk {
      margin-top: 8px;
      margin-left: 4px;
      color: red;
    }
  }
  & .ant-col-title {
    font-size: 16px;
    font-weight: 500;
  }
}