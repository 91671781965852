.build-analytics-container {
  padding: 20px;
}
.analytics-query-container {
  .margin {
    margin-right: 10px;
  }
  .form-item {
    margin-bottom: 20px;
  }
  .note-row {
    padding-top: 10px;
    span {
      color: red;
    }
  }
  .icon {
    margin-right: 10px;
  }
  .action-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 10px;
  }
}
.anaytics-query-table {
  .query-table {
    margin-top: 20px;
  }
}