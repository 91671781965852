
.campaigns {
  display: flex;
  flex-direction: column;
  height: 100%;

  &_delete_modal {
    .ant-modal {
      &-title {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #2e333b;
      }

      &-body {
        p {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #2e333b;
        }
      }

      &-footer {
        text-align: center;
        .ant-btn-primary {
          background: red;
          border-color: red;
        }
      }
    }
  }
  .am {
    &__table__actions {
      .ant-btn {
        margin-left: 1em;
      }
    }
    &__email-editor {
      position: relative;
      display: flex;
      flex-grow: 1;
      &__stats {
        padding-bottom: 47px;
      }
      &__loader {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.8);
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        z-index: 3;
      }
      &--editor {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        #email-editor {
          flex-grow: 1;
        }
      }
      &__form {
        display: flex;
        flex-direction: column;
        padding: 1em;
        &__group {
          display: flex;
        }
        &__item {
          flex-grow: 1;
          margin: 0 1em;
          display: flex;
          flex-direction: column;
          &--attachments {
            display: block;
            margin-top: 1em;
            margin-bottom: 1em;
            ul {
              list-style: none;
              display: inline-block;
            }
          }
          &__group {
            display: flex;
            .ant-btn {
              margin-left: 10px;
            }
          }
        }
      }
      &__top-bar {
        width: 100%;
        background: #505659;
        color: #fff;
        padding: 5px;
        a,
        .ant-btn-link {
          color: #fff;
        }
      }
      &__bottom-bar {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        text-align: right;
        padding: 7px 20px;
        border-top: 1px solid #e8e8e8;
        .ant-btn {
          margin-left: 1em;
        }
      }
      &__test {
        .ant-input {
          max-width: 200px;
          margin-right: 1em;
        }
      }
      &__time {
        display: flex;
        &__item {
          margin: 0 1em;
          label {
            margin-right: 1em;
          }
          .ant-time-picker {
            margin-right: 1em;
          }
        }
      }
      &__filters {
        > ul {
          list-style: none;
          margin: 0;
          padding: 0;
          > li {
            padding: 0.5em;
            label {
              margin-right: 1em;
              .anticon {
                margin-left: 5px;
                cursor: pointer;
              }
            }
            .ant-select {
              min-width: 200px;
              max-width: 500px;
            }
          }
        }
        b {
          margin-top: 1em;
          display: block;
        }
      }
    }
        &__label--required {
      &::after {
        content: '*';
        margin-right: 2px;
        color: red;
      }
    }
  }
  .cm {
    &__page-header {
      border-bottom: 1px solid #e8e8e8;
      .ant-breadcrumb {
        margin: 0;
        a {
          color: #1890ff;
        }
      }
      &__right_btn {
        position: absolute;
        right: 1em;
        top: 1em;
      }
    }
    &__table {
      .ant-table { 
        &-column-title {
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.46;
          letter-spacing: normal;
          color: #4d535e;
          i {
            margin-left: 0.5em;
          }
        }
      }
      &__actions {
        &_edit_link{
            margin-right: 0.5em;
            padding-left: 0;
        }
        &_delete {
          color: #cf1322;
          border-left: 1px solid #e4e6eb;
        }

      }
      &__name {
        width: 60%;
      }
    }
  }
  &__modal {
    &--preview-email-template {
      .am {
        &__preview-email-template {
          width: 100%;
          border: none;
          min-height: 250px;
        }
      }
    }
    &--merge-tags {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          display: inline-block;
          margin: 10px;
        }
      }
    }
    &--delete-template {
      text-align: center;
    }
  }
  &_new {
    padding: 0px 0px calc(64px + 1em);
    &_cm {
      &__table {        
        &__actions {
          &_block {
            display: inline-flex;
          }
          &_edit {
              margin-right: 0.5em;
              padding: 0;
          }
          &_delete {
            color: #cf1322;
            border-left: 1px solid #e4e6eb;
          }
        }
      }
    }
    &_require_module--label {
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: normal;
      color: #2e333b;
      margin-bottom: 0.5em;
      &:after {
        content: '*';
        margin-right: 2px;
        color: red;
      }
    }

    &_module--label {
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: normal;
      color: #2e333b;
      margin-bottom: 0.5em;
    } 
    &_footer {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      background-color: #fff;
      padding: 16px 14px;
      &--btn-group {
        float: right;
        button + button {
          margin-left: 0.5em;
        } 
      }
    }
    &--status {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #2e333b;
      float: right;
      button {
        margin-left: 0.5em;
      }
    }
  }
}
