body {
    height: 100%;
    &.superadmin-no-pad {
        padding-left: 0px!important;
    }
}
.admin-auth-box {
    width: 100%;
    height: 100%;
    position: fixed;
    background: url("https://campuskudos-uploads.s3.amazonaws.com/admin/FTfisGo7SYGlC0zwBvU2_bg_v.png.jpg") center center / cover no-repeat;
    z-index: 10;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .inner-auth-box {
        text-align: center;
        padding: 25px;
        width: 400px;
        max-width: 100%;
        background: rgba(255, 255, 255, .9);
        border-radius: 25px;
        img {
            width: 200px;
        }
    }
    .admin-button {
        margin-top: 25px;
        display: inline-block;
        font-size: 24px;
        padding: 10px 25px;
        background: #db3236;
        color: #fff;
        cursor: pointer;
    }
    .support-search-button {
        margin-top: 25px;
    }
    .error {
        // color: $red;
    }
}
  