.aam_page {
  &__header {
    .page_select {
      width: 180px;
    }
  }
}

.dtFilters {
  margin-bottom: 20px;
  .v_divider {
    height: inherit;
  }
  .dateFilter {
    label {
      margin-right: 10px;
    }
  }
  .filterActions {
    display: flex;
    gap: 12px;
  }
}