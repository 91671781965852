.templates-and-tips {
  .ant-tabs .ant-tabs-top-content,
  .ant-tabs .ant-tabs-bottom-content {
    padding: 0;
  }

  .ant-table-row {
    cursor: pointer;
  }

  &-form-footer {
    .ant-form-item-children {
      display: flex;
      justify-content: space-between;
    }
  }

  &-ellipsis-cell {
    width: 291px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
