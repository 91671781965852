.App 
    height: 100%
    overflow: hidden
.heading__h3
  font-size: 1.6em
  border-bottom: 4px solid white
  padding-bottom: 5px
  width: auto

.ck-card 
  background: #fff
  padding: 15px
  position: relative

