.career-path-form-container {
  .career-path-form-loader {
    margin-left: 45%;
  }
  .action-button-container {
    display: flex;
    justify-content: end;

    button {
      margin-left: 15px;
    }
  }
}
.ant-form-item-label {
  width: 100%;
  text-align: left;
}
.changelog-input > * {
  border: 3px solid #1890ff;
}
.changelog-input > span {
  border: none;
}

.manage-occupations-container {
  p {
    width: 75%;
  }
  .career-path-cell {
    > div {
      display: flex;
    }
    display: flex;
    justify-content: space-between;
  }
}

.manage-subject-areas-button-group {
  display: flex;
  justify-content: space-between;
}

.manage-programs-container {
  .description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
