.default-text {
  &-id {
    width: 100px;
    max-width: 100px;
    min-width: 100px;
  }
  &-identifier {
    width: 200px;
    max-width: 200px;
    min-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-value {
    width: 300px;
    max-width: 300px;
    min-width: 300px;
  }
  &-modules {
    width: 200px;
    max-width: 200px;
    min-width: 200px;
  }
  &-modified-count {
    width: 100px;
    max-width: 100px;
    min-width: 100px;
  }
  &-modified-date {
    width: 200px;
    max-width: 200px;
    min-width: 200px;
  }
  &-actions {
    width: 150px;
    max-width: 150px;
    min-width: 150px;
  }
}
