.company-industry-model {
  &__company {
    margin-bottom: 12px;
    .ant-form-item {
      margin-bottom: 4px;
    }
    &__details {
      display: flex;
    }
    &__avatar {
      margin-right: 10px;
    }
    &__info-wrapper {
      flex-grow: 1;
      .ant-form-item-label {
        text-align: left;
      }
      .ant-select-disabled {
        color: rgba(0, 0, 0, 0.5);
      }
    }
    &__info-wrapper2 {
      display: flex;

      button {
        margin: 8px 0;
      }
    }
    &__info {
      flex-grow: 1;
    }
    &__label {
      font-size: 16px;
      line-height: 1.4;
      margin-bottom: 12px;
    }
    &__subtext {
      font-weight: 500;
      font-size: 14px;
      line-height: 1.4;
      margin: 4px 0;
    }
  }
}
