.multiselect{
  &__control-button{
      cursor: pointer;
      &-selected{
          font-weight: bold;
      }
  }
  &__controls{
      .right{
          float: right;
      }
  }
}