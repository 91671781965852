.alert_module_container {
  .ant-form-item {
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: 'tnum', 'tnum';
    margin-bottom: 0px;
    vertical-align: top;
  }
  .submit-btn {
    text-align: right;
  }
  .quill-editor {
    min-height: 120px;
    &:global(.quill-wysiwyg-editor) {
      :global(.ql-tooltip) {
        z-index: 100;
        :global(.ql-preview) {
          line-height: inherit;
        }
      }
    }
  }
  .image-container {
    width: 138px;
    height: 125px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 90px;
    transition: transform 0.2s;
    position: relative;
  }
  .image-container:hover {
    transform: scale(1.1);
  }
  .active-border {
    border-color: #52c41a;
  }
  .row {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(124px, 1fr));
    grid-gap: 30px 35px;
  }
  .checked-icon {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .row-justify {
    display: flex;
    justify-content: space-between;
  }
  .banner-table {
    margin-top: 20px;
  }
  .primary-btn {
    margin-left: 10px;
  }
}

.cover-media-container {
  margin-left: 20px;

  & > div {
    margin: 10px 0px;
  }

  .label {
    margin-bottom: 0;
  }
}

.custom-image-input-container {
  margin-bottom: 10px;
}

.video-preview-container  {
  width: 400px;
  height: 225px;

  & iframe {
    width: 400px;
    height: 225px;
  }
}

// :global {
//   .quill-wysiwyg-editor {
//     .ql-tooltip {
//       z-index: 100 !important;
//       .ql-preview {
//         line-height: inherit;
//       }
//     }
//   }
// }
