.upsell {
  padding-bottom: 5em;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  padding: 1em 2em;
}
