.layout_parent {
  min-height: 100vh;
  .layout_header {
    padding-left: 250px;
  }
  .layout_content {
    padding-left: 250px;
  }
}

.layout_parent_collapsed {
  .layout_header {
    padding-left: 80px;
  }
  .layout_content {
    padding-left: 80px;
  }
}

.layout__sidebar {
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  overflow: hidden auto;
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 2;
}

.layout__menu {
  padding-bottom: 48px;
}

.logo {
  max-width: 100%;
  padding: 10px 20px;
  height: 64px;
  background: #002140;
  margin-bottom: 10px;
  & img {
    max-width: 100%;
    max-height: 100%;
  }
}

.layout_header {
  background: white;
  padding: 0px 20px;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  display: flex;
  justify-content: space-between;
  z-index: 1;
  transition: padding-left 0.2s;

  & h2 {
    color: gray;
    text-align: left;
    margin: 0;
    padding-left: 20px;
  }
  & h3 {
    margin: 0px;
    color: gray;
    margin-right: 20px;
  }
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.layout_content {
  overflow: hidden auto;
  height: calc(100vh - 64px);
  transition: padding-left 0.2s;
}

.navText {
  font-size: 14px;
}
