.simple-table {
  td {
    border-bottom: 1px solid #ccc;
    padding: 5px 10px;
  }
  th {
    font-weight: bold;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 5px 10px;
  }
}
.automated-marketing {
  display: flex;
  flex-direction: column;
  height: 100%;
  .am {
    &__page-header {
      border-bottom: 1px solid #e8e8e8;
      .ant-breadcrumb {
        margin: 0;
        a {
          color: #1890ff;
        }
      }
    }
    &__table {
      &__stats {
        ul {
          padding: 0;
          margin: 0;
        }
        &__label {
          font-weight: bold;
          text-transform: capitalize;
        }
      }
      &__actions {
        .ant-btn {
          margin-right: 1em;
        }
      }
    }
    &__label--required {
      &:before {
        content: '*';
        margin-right: 2px;
        color: red;
      }
    }
    &__email-editor {
      position: relative;
      display: flex;
      flex-grow: 1;
      &__stats {
        padding-bottom: 47px;
      }
      &__loader {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.8);
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        z-index: 3;
      }
      &--editor {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        #email-editor {
          flex-grow: 1;
        }
      }
      &__form {
        display: flex;
        flex-direction: column;
        padding: 1em;
        &__group {
          display: flex;
        }
        &__item {
          flex-grow: 1;
          margin: 0 1em;
          display: flex;
          flex-direction: column;
          &--attachments {
            display: block;
            margin-left: 0;
            ul {
              list-style: none;
              display: inline-block;
              margin-left: 10px;
              padding-left: 0;
              .ant-tag {
                margin-top: 2px;
              }
            }
          }
          &__group {
            display: flex;
            .ant-btn {
              margin-left: 10px;
            }
            .ant-select {
              width: 30%;
            }
          }
        }
      }
      &__top-bar {
        width: 100%;
        background: #505659;
        color: #fff;
        padding: 5px;
        a,
        .ant-btn-link {
          color: #fff;
        }
      }
      &__bottom-bar {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        text-align: right;
        padding: 7px 20px;
        border-top: 1px solid #e8e8e8;
        .ant-btn {
          margin-left: 1em;
        }
      }
      &__test {
        .ant-input {
          max-width: 200px;
          margin-right: 1em;
        }
      }
      &__time {
        display: flex;
        &__item {
          margin: 0 1em;
          label {
            margin-right: 1em;
          }
          .ant-time-picker {
            margin-right: 1em;
          }
        }
      }
      &__filters {
        > ul {
          list-style: none;
          margin: 0;
          padding: 0;
          > li {
            padding: 0.5em;
            label {
              margin-right: 1em;
              .anticon {
                margin-left: 5px;
                cursor: pointer;
              }
            }
            .ant-select {
              min-width: 200px;
              max-width: 500px;
            }
          }
        }
        b {
          margin-top: 1em;
          display: block;
        }
      }
    }
  }
  &__modal {
    &--preview-email-template {
      .am {
        &__preview-email-template {
          width: 100%;
          border: none;
          min-height: 250px;
        }
      }
    }
    &--merge-tags {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          display: inline-block;
          margin: 10px;
        }
      }
    }
    &--delete-template {
      text-align: center;
    }
  }
}
