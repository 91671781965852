.compass {
  .ant-tabs .ant-tabs-top-content,
  .ant-tabs .ant-tabs-bottom-content {
    padding: 0;
  }

  .ant-table-row {
    cursor: pointer;
    &.selected-row {
      background: #d1e8f0;
    }
  }

  &-form-footer {
    .ant-form-item-children {
      display: flex;
      justify-content: space-between;
    }
  }

  &-ellipsis-cell {
    width: 291px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .badge-icon-container {
    display: flex;
    flex-direction: column;
    .badge-icon-preview {
      width: max-content;
      height: 50px;
    }
  }
}
.compass_launch_modal_list_search {
  padding: 0.7em;
}

.compass_launch_modal_list_container {
  max-height: 24em !important;
}
