.access_management {
  .admins {
    :global(.ant-row), :global(.ant-row-flex) {
      padding: 24px 16px;
    }
    .add-container {
      display: flex;
      :global(.ant-btn) {
        margin-left: 12px;
      }
      .name-cont {
        width: 100%;
      }
      .chckbox {
        padding-top: 8px;
      }
    }
    .select-container {
      display: flex;
      align-items: center;
      :global(.anticon) {
        margin-left: 12px;
        cursor: pointer;
      }
    }
  }
}