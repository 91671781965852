.usertype-manage {
  .main-container {
    padding: 16px;
    .manage-table-container {
      background-color: #fff;
      .row-justify {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 16px 16px 24px;
      }
      &__heading {
        font-size: 16px;
        color: #2e333b;
      }
    }
  }

  .header__title {
    font-size: 16px;
    color: #2e333b;
  }

  .ant-page-header {
    padding: 10px 16px;
  }

  .ant-breadcrumb {
    margin-bottom: 9px;
    &-link {
      font-size: 13px;
      color: #696f7a;
    }
  }

  .showmore-btn {
    font-size: 14px;
    display: block;
    font-weight: bold;
    color: #1b90ff;
    margin-left: 3px;
    transition: all 0.2s ease-in-out;
    outline: none;
    text-transform: uppercase;
    &:hover {
      cursor: pointer;
      color: #0054a7;
    }
  }
}

.usertype-actions {
  .ant-btn {
    font-size: 13px;
    padding: 0;
  }
  &__delete {
    color: #cf1322;
    &:hover,
    &:active,
    &:focus {
      color: #cf1322;
    }
    .ant-btn {
      &:disabled {
        color: #cf1322;
        opacity: 0.5;
        &:hover {
          color: #cf1322;
          opacity: 0.5;
        }
      }
    }
  }
}

.pg-tag-select {
  position: relative;
  &--input {
    .ant-select-selection--multiple {
      padding-bottom: 5px;
      .ant-select-selection__rendered {
        width: 65%;
      }
      .ant-select-selection__rendered > ul {
        margin-left: 6px;
        li {
          margin-top: 5px;
        }
      }
      .ant-select-search {
        &__field {
          padding: 0px;
        }
      }
      .ant-select-selection__placeholder {
        font-size: 14px;
        z-index: 2;
        line-height: 1.8;
      }
      .ant-select-selection__choice {
        background-color: #e6f7ff;
        color: #096dd9;
        border-color: #e6f7ff;
        border-radius: 13px;
        height: 26px;
        padding-top: 1px;
        display: flex;
        justify-content: center;
        align-items: center;
        &__content {
          font-size: 13px;
          line-height: 1.5;
          color: #096dd9;
          margin-right: 6px;
          vertical-align: text-top;
        }
        &__remove {
          color: #ffffff;
          background-color: #096dd9;
          border-radius: 50%;
          line-height: 0.9;
          padding: 3px;
          i {
            line-height: 0;
          }
        }
        &__remove::before {
          font-size: 16px;
        }
      }
      .ant-select-selection__choice &.ant-select-selection {
        border-color: #e4e6eb;
        &__rendered {
          line-height: 36px;
        }
        &__rendered > ul {
          margin-left: 6px;
        }
        &__rendered > ul > li {
          margin-top: 5px;
          height: 26px;
        }
      }
    }
  }
  &--err {
    .ant-select-selection--multiple {
      // background-color: #fff1f0;
      &.ant-select-selection {
        border: solid 1px #f5222d;
        &:hover,
        &:focus,
        &:active {
          border-color: #f5222d;
          box-shadow: none;
          outline: 0;
        }
        &::selection {
          background: rgba(207, 19, 34, 0.35);
        }
      }
      .ant-select-search--inline {
        color: #f5222d;
      }
    }
    &-label {
      color: #f5222d;
      font-size: 14px;
      margin-top: -10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  &--dropdown {
    visibility: hidden;
  }
  &--help {
    position: absolute;
    color: #696f7a;
    right: 8px;
    top: 3px;
    span {
      margin-left: 4px;
    }
  }
}

.usertype-alert {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: solid 1px #a5d0fe;
  background-color: #e6f7ff;
  padding: 14px 16px 15px;
  margin-bottom: 16px;
  opacity: 1;
  height: auto;
  &__message {
    font-size: 13px;
    color: #096dd9;
  }
  &__btn {
    background-color: transparent;
    border: none;
    padding: 10px 0px 10px 10px;
    &:hover {
      cursor: pointer;
    }
  }
}
