.benchmarkingModal {
  .text {
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 16px;
    
    color: #4D535E;
  }
}

.benchmarkingGroupsList {
  margin: 20px;

  .actions {
    display: flex;
    justify-content: space-between;

    .divider {
      height: 32px;
    }
  }
}