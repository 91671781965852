.fsp-picker__brand {
  text-align: center;
  color: rgba(0, 0, 0, 0) !important;
  width: 0;
  height: 0; }
  .fsp-picker__brand span {
    display: none; }
  .fsp-picker__brand::after {
    color: white;
    content: "Powered by PeopleGrove"; }
