.standard-hub {
  &--title {
    display: flex;
    align-items: center;
    .ant-tag {
      margin-left: 12px;
      font-weight: bold;
    }
  }
  &--list-card {
    &__button-group {
      .standard-fields-trigger {
        margin-right: 10px;
      }
    }
    &__cta {
      margin-top: 34px;
      @media screen and (max-width: 1200px) {
        margin-top: 12px;
      }
      &-custom-fields {
        margin-right: 10px;
      }
      &--green {
        color: #389e0d;
        background: #f6ffed;
        border-color: #b7eb8f;
        &:hover,
        &:active,
        &:focus {
          cursor: default;
          color: #389e0d;
          background: #f6ffed;
          border-color: #b7eb8f;
        }
      }
      &--gold {
        color: #d48806;
        background: #fffbe6;
        border-color: #ffe58f;
        &:hover,
        &:active,
        &:focus {
          color: #d48806;
          background: #fffbe6;
          border-color: #d48806;
        }
      }
      &--red {
        color: #f5222d;
        background: #fff1f0;
        border-color: #ffa39e;
        &:hover,
        &:active,
        &:focus {
          color: #f5222d;
          background: #fff1f0;
          border-color: #f5222d;
        }
      }
    }
  }
  &--manage-config {
    &--main-container {
      margin: 20px;
      .config-content {
        background-color: transparent;
        .ant-collapse-item {
          overflow-y: auto;
          max-height: calc(100vh - 480px);
          margin-top: 20px;
          background-color: #fff;
        }
        .empty-state {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          &__heading {
            font-size: 16px;
            color: #2e333b;
            margin-top: 16px;
            margin-bottom: 4px;
            &_sub {
              font-size: 14px;
              color: #4d535e;
              margin-top: 0;
            }
          }
        }
      }
      .warning__card {
        margin-top: 20px;
        border: solid 1px #e4e6eb;
        background-color: #ffffff;

        .warning__text {
          font-size: 16px;
          font-weight: normal;
          color: #2e333b;
          margin-bottom: 0px;
        }

        .warning__description {
          padding-right: 60px;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #2e333b;

          .warning__text-bold {
            color: #cf1322;
            font-weight: bold;
          }
        }
      }
    }
  }
  &--verification-modal {
    min-width: calc(100vw - 400px);
    &--body {
      .error-state {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          height: 25px;
          width: 25px;
        }
        &__heading {
          font-size: 14px;
          color: #2e333b;
          margin-top: 16px;
          margin-bottom: 4px;
        }
      }
      .valid-state {
        &--heading {
          color: #2e333b;
          font-size: 16px;
          text-align: center;
        }
        &--selections {
          display: grid;
          gap: 20px;
          &__left {
            grid-column: 1;
          }
          &__right {
            grid-column: 2;
          }
        }
        &--select {
          width: 50%;
          &__label {
            color: #2e333b;
            font-size: 14px;
            margin-bottom: 8px;
          }
        }
        &--json-view {
          &__json {
            max-height: calc(100vh - 400px);
            overflow-y: auto;
          }
          &__table-header {
            text-align: center;
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 12px;
            margin-top: -12px;
          }
          .ant-table {
            max-height: calc(100vh - 400px);
            overflow-y: auto;
          }
        }
        &--switch {
          margin-top: 12px;
          display: flex;
          align-items: center;
          &__label {
            margin-right: 8px;
          }
        }
      }
    }
    .ant-modal-footer {
      text-align: center;
    }
  }

  &--status-modal {
    &--body {
      text-align: center;
      &__title {
        color: #2e333b;
        font-size: 16px;
        margin-bottom: 8px;
      }
      &__domain {
        color: #2e333b;
        font-size: 16px;
        margin-bottom: 8px;
      }
      &__desc {
        color: #2e333b;
      }
      &__status {
        color: #2e333b;
        &--migration {
          color: #2e333b;
          margin-bottom: 8px;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
    .ant-modal-footer {
      text-align: center;
    }
  }
}

.modal-map-custom-fields {
  & .custom-fields {
    & .field-item {
      display: flex;
      justify-content: space-between;
    }
  }
}
.modal-standard-custom-fields {
  max-width: 550px;
  .success-message {
    margin-top: 20px;
    padding: 7px;
    border-radius: 8px;
    color: #fff;
    background: rgb(35, 196, 78);
    margin-bottom: 0;
  }
}
