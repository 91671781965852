.quill-wysiwyg-editor {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;

  .ql-toolbar {
    flex: 0 0 38px;
    display: flex;
    align-items: center;
    border: 1px solid #d9d9d9;
  }
  .ql-container {
    flex: 1 1 auto;
    border: 1px solid #d9d9d9;

    display: flex;
    flex-direction: row;

    .ql-editor {
      width: 100%;
      height: auto;
    }
  }

  .ql-picker-label::before {
    position: absolute;
  }
}