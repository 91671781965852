.add-hub-to-sync-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.add-hub-btn {
  margin-top: 10px;
}

.analytics_profile__popover__header {
  max-width: 400px;
}

.analytics-profile--row-actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 14px;
  &__error {
    font-size: 12px;
    color: #cf1322;
    font-weight: bold;
    font-style: italic;
  }
}

.analytics-profile--warning {
  margin: 20px 0;
  border: solid 1px #e4e6eb;
  background-color: #ffffff;
  &__description {
    width: 100%;
    padding-right: 60px;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #2e333b;
    :is(span) {
      color: #cf1322;
      font-weight: bold;
    }
  }
}

.analytics-profile--table {
  background-color: #ffffff;
  padding: 24px;
}
