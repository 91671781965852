.user-detail__container {
    padding: 10px;
}

.user-detail__inputs {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-bottom: 1px solid grey;
}

.user-detail__inputs--input {
    width: 300px;
}

.user-detail__inputs--button {
    margin-left: 10px;
}

.user-detail__user-container {
    padding: 10px;
}

.user-detail__user--margin {
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 0px;
}

.user-detail__subcontent--margin {
    margin-left: 60px;
}

.user-detail__unlink {
    color: red;
    cursor: pointer;
}

.user-detail__cluster--label {
    background-color: lighten($color: #1890ff, $amount: 15) ;
}