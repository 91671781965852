.pathway-provisioning {
  .container {
    padding: 2em 1em;
    .list {
      .ant-tabs-bar {
        margin: 0;
        border: none;
        .ant-tabs-tab {
          font-size: 16px;
          color: #2e333b;
          border: none;
          outline: none;
          cursor: default;
        }
      }
      &__card {
        .ant-card-head {
          padding: 0 16px;
        }
        .ant-card-body {
          padding: 0;
        }
      }
      &__header {
        display: flex;
        justify-content: space-between;
      }
      &__table {
        &-box {
          max-width: 15em;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .ant-pagination {
          margin: 16px;
        }
      }
    }
    .form {
      &__copy {
        width: 100%;
        height: 36px;
        border-radius: 4px;
        border: solid 1px #096dd9;
        color: #096dd9;
        background-color: #ffffff;
      }
      .ant-form-item {
        margin-bottom: 0;
      }
      .ant-form-item-label {
        font-size: 14px;
        color: #2e333b;
      }
      &__card {
        margin-top: 1em;
        font-size: 13px;
        & > .ant-card-head {
          margin: 0;
          padding: 0 0.5em;
          border-bottom: none;
          font-size: 14px;
          color: #2e333b;
          background-color: #f4f5f7;
        }
        & > .ant-card-body {
          padding: 0;
          background-color: #f4f5f7;
          p {
            padding: 0 0.5em;
            color: #696f7a;
          }
        }
        &-extra {
          display: flex;
          justify-content: space-between;
          align-items: center;
          & > span {
            color: #4d535e;
            padding: 0 0.5em;
            font-size: 13px;
          }
        }
        &-table {
          .ant-table-tbody {
            font-size: 13px;
            color: #2e333b;
            background-color: #f4f5f7;
          }
          .ant-btn {
            padding-left: 0px;
          }
        }
      }
      &__footer {
        margin-top: 1em;
        &-right {
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
}

.hubs_pathways_modal {
  .ant-modal-body {
    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-form-item-label {
      font-size: 14px;
      color: #2e333b;
    }
    .ant-select-selection {
      height: 36px;
      border-radius: 4px;
      border: solid 1px #e4e6eb;
      line-height: 0;
    }
    .ant-select-selection__rendered {
      line-height: 33px;
    }
    p {
      font-size: 16px;
      color: #2e333b;
    }
  }
  .ant-modal-footer {
    text-align: center;
    .ant-btn {
      width: 90px;
      height: 36px;
      border-radius: 4px;
    }
    .ant-btn.confirm-button {
      width: unset;
    }
  }
  .alert-text{
    padding: 0;
    margin: 0;
    i{
      font-size: .8em;
      color: #4d535e;
    }
  }
}

.provisioning_launch_modal {
  .ant-modal-body {
    min-height: 35em;
    max-height: 35em;
    padding: 0;
    .ant-tabs-nav {
      width: 50%;
    }
    .ant-tabs-tab {
      width: 100%;
      margin: 0;
      font-size: 16px;
      text-align: center;
      color: #2e333b;
    }
    .ant-tabs-ink-bar {
      width: 100%;
    }
    .ant-tabs-bar {
      margin: 0;
    }
  }

  &-search {
    width: 100%;
    max-height: 28em;
    overflow-y: scroll;
    .ant-select-selection--multiple {
      min-height: 40px;
      border-radius: 0;
      .ant-select-selection__placeholder {
        height: 30px;
        line-height: 26px;
      }
      & ul > li.ant-select-selection__choice {
        margin-top: 7px;
      }
    }
  }
  .ant-modal-footer {
    text-align: center;
    .ant-btn {
      width: 90px;
      height: 36px;
      border-radius: 4px;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    font-size: 13px;
    font-weight: bold;
    background-color: #f4f5f7;
    color: #4d535e;
    padding: 15px 24px;
    &-search {
      width: 50%;
    }
  }
  &__list {
    padding: 0.7em;
    max-height: 20em;
    overflow-y: scroll;
  }
  &__hub {
    display: flex;
    justify-content: space-between;
    span.hub_name {
      display: inline-block;
      padding: 0.7em 0em;
      max-width: 90%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 15px;
    }
  }
  .empty_state {
    padding: 1em;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    color: #4d535e;
  }
  &__row {
    height: 26em;
    overflow-y: scroll;
    .ant-col {
      padding: 15px 24px;
      color: #2e333b;
    }
    &-module {
      height: 30em;
    }
  }
}

.confirm_hub_filters {
  text-align: center;

  .ant-modal-body {
    min-height: 450px;
    max-height: 750px;
    padding: 10px 20px 0px 20px;
  }

  .provisioning_launch_modal {
    &__header {
      font-size: 14px;
      padding-left: 10px;
      .header-warning {
        color: #cf1322;
        font-weight: normal;
      }
    }
    &__list {
      max-height: 19em;
    }
  }
}
.confirm_hub_filters:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 0;
}
.confirm_hub_filters .ant-modal {
  display: inline-block;
  vertical-align: middle;
  top: 0;
  text-align: left;
}

.pathway_filter {
  top: 40px;

  .ant-modal-body {
    min-height: 600px;
    max-height: 750px;
    padding: 20px 20px 0px 20px;
    text-align: center;
    overflow-y: scroll;
  }
  .ant-modal-footer {
    text-align: center;
    .ant-btn {
      width: 90px;
      height: 36px;
      border-radius: 4px;
    }
  }
  .ant-tabs {
    padding-top: 20px;
    .ant-tabs-ink-bar {
      z-index: 0;
    }
  }

  .single-row {
    margin-bottom: 20px;
    display: flex;
    position: relative;
  }

  .heading {
    margin: 10px 0px 20px 0px;
  }

  .virtual-select {
    width: 820px;
    text-align: left;
    .Select-value {
      font-size: 14px;
      .Select-value-label {
        color: #4d535e;
      }
    }
  }

  .inline-text {
    margin: 0 10px;
    background-color: #f2f2f2;
    color: #505050;
    text-align: center;
    padding: 10px 9px 11px;
    font-size: 13px;
    font-weight: 500;
    line-height: 10px;
    border-radius: 3px;
    height: 32px;
  }

  .add-filters {
    width: 100%;
  }

  .cross-icon {
    position: absolute;
    right: 0px;
    top: 8px;
    font-size: 16px;
    cursor: pointer;
  }
}
