.load-testing {
  margin: 20px;
}

.load-testing-view {
  margin: 20px;
}

.load-testing-view-heading {
  display: flex;
  margin: 24px 0;

  button {
    margin-left: 64px;
  }
}
