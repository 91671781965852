.main-container {
  padding: 20px;
  .ant-card-body {
    padding: 16px 24px;
  }

  .ant-btn-primary {
    &:disabled {
      font-style: italic;
      border-radius: 4px;
      color: #696f7a;
      border: solid 1px #e4e6eb;
      background-color: #f4f5f7;
    }
    background-color: #096dd9;
    border-color: #096dd9;
  }

  .warning__card {
    border: solid 1px #e4e6eb;
    background-color: #ffffff;

    .warning__text {
      font-size: 16px;
      font-weight: normal;
      color: #2e333b;
      margin-bottom: 0px;
    }

    .warning__description {
      padding-right: 60px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #2e333b;

      .warning__text-bold {
        color: #cf1322;
        font-weight: bold;
      }
    }
  }
}

.header__title {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: bold;
  color: #2e333b;
}

.manage-table-container {
  margin-top: 20px;
  width: 100%;

  &__heading {
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: normal;
    color: #2e333b;
  }

  button.ant-btn {
    height: 36px;
  }

  .ant-tabs-bar {
    border-bottom: 1px solid #fff;
  }

  .ant-tabs-bar {
    margin: 0;
  }

  .ant-tabs .ant-tabs-top-content,
  .ant-tabs .ant-tabs-bottom-content {
    padding: 16px 0 0 0;
    background: #ffffff;
  }

  .ant-tabs.ant-tabs-card .ant-tabs-card-bar {
    color: #696f7a;
    font-size: 16px;
    .ant-tabs-tab-active {
      color: #2e333b;
      font-weight: bold;
    }
  }

  .ant-table-column-title {
    font-size: 13px;
    font-weight: bold;
    line-height: 1.46;
    color: #4d535e;
  }

  .ant-table-pagination.ant-pagination {
    margin: 16px 30px;
  }

  .row-justify {
    display: flex;
    justify-content: space-between;
    padding: 4px 20px;
  }

  .banner-table {
    margin-top: 20px;
  }
}

.form-container {
  background-color: #ffffff;

  &__heading {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #2e333b;
  }

  .ant-input {
    height: 36px;
  }

  .ant-divider-horizontal {
    height: 2px;
    margin: 24px 0 12px 0;
  }

  textarea.ant-input {
    min-height: 80px !important;
  }

  .user-type {
    &__container {
      border-radius: 4px;
      background-color: #f4f5f7;
      padding: 16px;
      margin-top: 16px;

      hr {
        border: 0;
        border-top: 1px solid #e4e6eb;
      }
    }

    &__title {
      font-size: 14px;
      font-weight: normal;
      color: #2e333b;
      margin-bottom: 0px;
    }

    &__sub-text {
      font-size: 13px;
      font-weight: normal;
      color: #696f7a;
    }

    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 36px;
      margin-bottom: 16px;

      &:first-of-type {
        margin-top: 24px;
      }

      &:last-of-type {
        margin-bottom: 0px;
      }

      .ant-select-selection__rendered {
        line-height: 34px;
      }
    }

    &__switch-text {
      font-size: 13px;
      font-weight: normal;
      color: #4d535e;
      // margin-left: 8px;
    }

    &__select-error {
      .ant-select-selection {
        border: 1px solid #eca1a7;
        background-color: #fff1f0;
      }
    }
  }

  .ant-form-item {
    margin: 5px 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    font-feature-settings: 'tnum', 'tnum';
    margin-bottom: 0px;
    vertical-align: top;

    .is-active-text {
      color: #2e333b;
    }
  }

  .full__width {
    width: 100%;
  }

  button.ant-btn {
    height: 36px;
  }

  .copy-btn {
    border-radius: 4px;
    border: solid 1px #096dd9;
    background-color: #ffffff;
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    color: #096dd9;
  }

  .ant-select-selection {
    min-height: 36px;
  }

  .ant-form-item-label > label::after {
    content: '';
  }

  .buttons {
    margin: 30px 0 20px 0;
    display: flex;
    justify-content: space-between;

    .delete-btn {
      &:disabled {
        height: 36px;
        font-style: italic;
        border-radius: 4px;
        color: #696f7a;
        border: solid 1px #e4e6eb;
        background-color: #f4f5f7;
        &:hover {
          color: #696f7a;
          border: solid 1px #e4e6eb;
          background-color: #f4f5f7;
        }
      }
      border-radius: 4px;
      border: solid 1px #e4e6eb;
      background-color: #ffffff;
      color: #cf1322;

      &:hover {
        background-color: #cf1322;
        color: #ffffff;
      }
    }

    .buttons__right > button:first-child {
      margin-right: 10px;
    }
  }
}

.copy-modal {
  .ant-select-selection {
    min-height: 36px;
  }

  .ant-select-selection__rendered {
    line-height: 34px;
  }

  .ant-form-item-label > label::after {
    content: '';
  }

  .ant-modal-footer {
    text-align: center;
  }

  .ant-modal-body {
    padding: 24px;
  }

  .ant-modal-header {
    padding: 16px;
  }

  .ant-modal-title {
    color: #2e333b;
  }

  .ant-form-item {
    margin-bottom: 8px;
  }

  .ant-btn {
    width: 96px;
    height: 36px;
  }
}

.confirmation-modal {
  .ant-modal-footer {
    text-align: center;
  }

  .ant-btn-primary {
    background-color: #096dd9;
    border-color: #096dd9;
  }

  .ant-modal-footer {
    text-align: center;
    padding: 16px;

    .delete-btn {
      border-radius: 4px;
      border: solid 1px #e4e6eb;
      background-color: #cf1322;
      color: #ffffff;

      // &:hover {
      //   background-color: #ffffff;
      //   color: #cf1322;
      // }
    }
  }

  .ant-modal-body {
    padding: 24px;
    font-size: 16px;
    line-height: 1.38;
    color: #2e333b;
  }

  .ant-modal-header {
    padding: 16px;
  }

  .ant-modal-title {
    color: #2e333b;
  }

  .ant-form-item {
    margin-bottom: 8px;
  }

  .ant-btn {
    width: 96px;
    height: 36px;
  }
}
