.manage-usertype-templates {
  .main-container {
    padding: 16px;
    .usertype-card {
      border-radius: 4px;
      background-color: #ffffff;
      border: solid 1px #e4e6eb;
      margin-top: 16px;
      &__title {
        font-size: 16px;
        color: #2e333b;
        margin: 0;
        padding: 16px 24px;
      }
      &__table {
        &-index {
          font-size: 13px;
          color: #2e333b;
        }
        &-name {
          font-size: 13px;
          font-weight: bold;
          color: #2e333b;
        }
        &-aschubs {
          &__value {
            font-size: 13px;
            color: #2e333b;
          }
        }
        &-actions {
          .ant-btn {
            font-size: 13px;
            padding: 0;
          }
          &__delete {
            color: #cf1322;
            &:hover,
            &:active,
            &:focus {
              color: #cf1322;
            }
            .ant-btn {
              &:disabled {
                color: #cf1322;
                opacity: 0.5;
                &:hover {
                  color: #cf1322;
                  opacity: 0.5;
                }
              }
            }
          }
        }
      }
    }
  }
  .empty-state {
    height: calc(100vh - 151px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__heading {
      font-size: 16px;
      color: #2e333b;
      margin-top: 16px;
      margin-bottom: 4px;
      &_sub {
        font-size: 14px;
        color: #4d535e;
        margin-top: 0;
      }
    }
  }
}

.manage-usertype-template {
  .main-container {
    padding: 16px;
    .footer-actions {
      margin-top: 32px;
      width: 100%;
      .ant-card-body {
        padding: 16px 24px;
        display: flex;
        justify-content: flex-end;
        .ant-btn-primary {
          margin-left: 12px;
        }
      }
      .ant-btn {
        height: 36px;
      }
    }
    .manage-template-form {
      margin-top: 16px;
      .ant-card-body {
        padding: 24px;
      }
      .ant-form-horizontal {
        display: flex;
        .ant-form-item {
          margin-bottom: 0 !important;
          &:first-of-type {
            margin-right: 24px;
          }
        }
      }
      .ant-select, .ant-input {
        width: 295px;
      }
      .ant-form-item {
        &-control-wrapper {
          .has-error {
            .ant-select-selection, .ant-input {
              border-color: #eca1a7;
            }
            .ant-form-explain {
              color: #cf1322;
            }
          }
        }
      }
    }
    .manage-template-table {
      margin-top: 16px;
      .manage-template-switch {
        &.active {
          .ant-switch-checked {
            background: #00875a;
          }
        }
      }
      .manage-template-select {
        .ant-select {
          width: 100%;
        }
        .select-upsell {
          &--err {
            .ant-select-selection {
              border: solid 1px #eca1a7 !important;
              background-color: #fff1f0 !important;
            }
            &-label {
              font-size: 13px;
              color: #cf1322;
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
}

.active-hubs-modal {
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-footer {
    text-align: center;
    .ant-btn {
      width: 90px;
      height: 36px;
      border-radius: 4px;
      border: solid 1px #e4e6eb;
      background-color: #ffffff;
    }
  }
  &__table {
    &--hub {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &__name {
        font-size: 13px;
        font-weight: bold;
        color: #2e333b;
      }
      &_identifier {
        font-size: 13px;
        color: #696f7a;
      }
    }
    &--cluster {
      &__name {
        font-size: 13px;
        color: #2e333b;
      }
    }
  }
}
