.tag {
  line-height: 20px;
  margin: 2px 10px 0px 10px;
  height: 24px;
  font-size: 13px;
  font-weight: bold;
  border-radius: unset;
}

.tag-break {
  font-size: 24px;
}
