.es-logstgash {
  padding: 16px;
  width: 100%;
  &-col--2 {
    display: flex; 
    align-items: center; 
    justify-content: center;
  }
  &-col--3 {
    text-align: right;
  }
  h3 {
    padding: 12px 0px;
  }
}
.es-logstgash-lodr {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}