.image-container {
  width: 98px;
  height: 80px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40px;
  transition: transform 0.2s;
  position: relative;
}
.image-container:hover {
  transform: scale(1.1);
}
.active-border {
  border-color: #52c41a;
}
.row {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(98px, 1fr));
  grid-gap: 12px;
}
.checked-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}
