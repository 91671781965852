.salesforce {
  display: flex;
  justify-content: left;

  button {
    background-color: aliceblue;
    border-radius: 3px;
    font-size: medium;
    color: #1789c1;
    padding-left: 10px;
    padding-right: 10px;
    border: none;
    margin: 2px 10px 0px 10px;
  }
}
