.root {
  :global .ant-page-header-content-view{
    text-align: center;
  }
  .clusterBtn{
    margin-right: 10px;
  }
  .activeBtn {
    color: #096dd9;
    border-color: #096dd9;
  }

}
