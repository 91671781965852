.container{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 33px;
  p{
    margin-bottom: 0;
  }
}

.row{
  width: 100%;
}

.contentConatiner{
  width: 77%;
  margin-left: auto;
  margin-right: auto;
}

.iconWrapper{
  text-align: center;
  width: 100%;
  margin: 4px;
  cursor: pointer;
  max-width: 100px;
  p{
    font-size: 12px;
    padding: 5px;
    background-color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.imageContainer{
  background-color: #dddddd;
  padding: 15px;
  width: 100%;
  height: 75px;
  div{
    width: 100%;
    height: 100%;
  }
  img{
    height: 100%;
    width: 100%;
  }
}

.spinnerWrapper{
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}