:global .ant-layout-has-sider {
    flex-direction: column !important;
}

.sider {
    right: 0;
    bottom: 0;
    z-index: 100;
    position: fixed;
    overflow: hidden !important;
    top: 0;
}

.container{
    height: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0 0 16px;

    h3 {
        color: #2E333B;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        margin-bottom: 0;
    }
}

.button{
    color: #2E333B;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    border: none;
    box-shadow: none;
}

.modalContentTitle {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 8px;

    p,
    h3 {
        margin-bottom: 0;
    }

    h3 {
        color: #2E333B;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        flex: 1;
    }
}

.timestamp {
    color: #696F7A;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 24px;

    span {
        margin-left: 12px;
        margin-right: 12px;
    }
}

.title {
    color: #2E333B;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 12px 0;
}

.body{
    padding: 0 16px 16px 16px;
    height: calc(100% - 65px);
    overflow: auto;
}

.overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.25);
}

.aiTag {
    flex: 0 1 auto;
    line-height: 24px;
    color: #CD26C2;
    text-align: center;
    font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    span{
        margin-left: 6px;
    }
}