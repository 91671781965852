.iconNameInput{
  :global .ant-input{
    padding-left: 34px !important;
  }
}

.iconCheckBoxWrapper{
  margin-top: 15px;
}

.iconSetInfo{
  margin: 15px 0;
}