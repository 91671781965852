.groups_programs_tier {
  .header-container {
    background-color: #ffffff;
    padding: 24px 16px;

    &__upper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
    }

    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__filter {
      border-top: 1px solid #e4e6eb;
      padding-top: 24px;
    }

    &__text {
      margin-bottom: 0;
      color: #2e333b;
      font-size: 16px;
      font-weight: bold;
    }

    &__btn {
      height: 36px;
    }

    &__input {
      height: 36px;
    }

    &__input-margin {
      margin-right: 16px;
    }

    &__btn-margin {
      margin-right: 16px;
    }
  }
}

.table-container {
  margin: 16px;
  background-color: #ffffff;
  padding: 24px;
}

.table__header {
  padding-bottom: 16px;
}

.table__header--text {
  font-size: 16px;
  font-weight: normal;
  color: #2e333b;
  margin-bottom: 0px;
}

.table-identifier-text {
  font-size: 13px;
  font-weight: normal;
  color: #696f7a;
}

.table-text {
  font-size: 13px;
  font-weight: bold;
  color: #2e333b;
  margin-bottom: 0;
}

.header__select {
  .ant-select-selection--multiple {
    min-height: 36px;
  }

  .ant-select-selection__rendered {
    line-height: 34px;
  }
}

.update-configuration {
  .ant-modal-footer {
    text-align: center;
  }

  &__message {
    font-size: 16px;
    font-weight: normal;
    color: #2e333b;
  }

  &__save-btn {
    background: #096dd9;
    border-color: #096dd9;
  }

  .ant-modal-body {
    padding: 0px;
  }

  .ant-modal-header {
    padding: 16px;
  }

  .ant-tabs-nav {
    width: 100%;
  }

  .ant-tabs-tab {
    width: 50%;
    margin: 0;
    text-align: center;
  }

  .ant-tabs-bar {
    margin: 0;
  }

  .ant-modal-title {
    color: #2e333b;
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: #096dd9;
  }

  .ant-tabs-ink-bar {
    background-color: #096dd9;
  }

  .ant-switch-checked {
    background-color: #00875a;
  }

  &__message {
    padding: 24px;
    text-align: center;

    p {
      margin-bottom: 0px;
    }
  }

  .tier-header {
    height: 53px;
    background-color: #f4f5f7;
    padding: 16px 23px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title {
      font-size: 13px;
      font-weight: bold;
      margin-bottom: 0;
      color: #4d535e;
    }
  }

  .template {
    max-height: 120px;
    overflow: hidden;
    transition: max-height 0.7s;
  }

  .tier-template {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;

    &__divider {
      height: 16px;
      margin: 0 12px;
    }

    &__icon {
      width: 16px;
      height: 16px;
      color: #d32734;
      cursor: pointer;
    }

    &__title {
      margin-bottom: 0px;
      font-size: 16px;
      font-weight: normal;
      color: #2e333b;
    }

    &__sub-text {
      display: block;
      font-size: 13px;
      font-weight: normal;
      color: #4d535e;
    }

    &--error {
      color: #cf1322;
    }

    &--warning {
      color: #d4380d;
    }

    &__view-more {
      text-align: center;
      border-bottom: 1px solid #e4e6eb;
      line-height: 0.1em;

      span {
        font-size: 13px;
        font-weight: bold;
        color: #096dd9;
        cursor: pointer;
        background: #fff;
        padding: 0 10px;
      }
    }
  }

  .max-allowed-container {
    padding: 16px 0;
    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .input-error {
      border: solid 1px #eca1a7;
      background-color: #fff1f0;
    }
  }

  .info {
    background-color: #f4f5f7;
    padding: 16px;
    margin: 16px 24px;
    border-radius: 4px;

    span {
      font-size: 13px;
      color: #4d535e;
      font-weight: bold;
    }
  }
}

.mt-16 {
  margin-top: 16px;
}

.update-delete {
  .ant-popover-buttons {
    text-align: center;
  }

  .ant-popover-buttons .ant-btn-primary {
    display: none;
  }

  .ant-btn-sm {
    height: 32px;
  }
}

.template-delete {
  .ant-popover-buttons {
    text-align: center;
  }

  .ant-btn-sm {
    height: 32px;
  }

  .ant-btn-danger {
    background-color: #cf1322;
    color: #fff;
  }
}
