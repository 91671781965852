.loader__container {
    width: 100%;
    height: 100%;
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader__spinner {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid transparent;
    border-radius: 50%;
    border-top-color: blue;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    to { -webkit-transform: rotate(360deg); }
}
  
@-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
}
