$primary: #1890ff;
$error: #f5222d;

.radio-login-reason-options {
  display: flex;
  align-items: center;
  height: 48px;
  border: 1px solid #d9d9d9;
  margin-bottom: 10px;
  padding-left: 6px;
  border-radius: 5px;

  .ant-form-item {
    margin-bottom: 0px;
  }
}

.radio-login-reason-options.ant-radio-wrapper-checked,
.radio-login-reason-options.ant-radio-wrapper-checked .ant-form-item-children {
  border-color: $primary;
  color: $primary;
}

.radio-login-reason-options:hover {
  color: $primary;
  .ant-form-item-children {
    color: $primary;
  }
}

.login-reason-modal {
  .custom-error {
    color: $error;
  }

  .ant-radio-group {
    width: 100%;
  }

  .ant-modal-body {
    padding-left: 38px;
    padding-right: 38px;
  }
}
