.headerContent {
  margin-top: 16px;
}

.activeModalSelectedHubText {
  font-weight: 700;
  padding: 8px;
}

.taskTriggerRow {
  padding: 8px;
}

.taskTriggerLabel {
  font-weight: bold;
}
