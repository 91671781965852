.table 
  \:global .ant-table-content
      background: white

.info
  justify-content: space-between
  padding: 10px

.content
  padding: 10px 

.defSeed
  display: flex
  flex-wrap: wrap
  & > div
    width: 100%
    padding-top: 10px
    & > label, div
      display: inline-block
    & > label
      width: 20%
    & > div
      width: 80%
    & > div.betweenFlex
      display: inline-flex
      justify-content: space-between


.tableTitle
  margin-left: 20px