.root {
  .addCta {
    width: 100%;
  }
}

.wrapperModal {
  z-index: 1000;
}

.changeLogWrapper {
  z-index: 1001;
}

.editModalWrapper {
  z-index: 1002;
}

.revertConfirmWrapper {
  z-index: 1003;
}
