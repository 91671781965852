.usertype_settings {
  .header-container {
    background-color: #ffffff;
    padding: 24px 16px 0 16px;;

    &__upper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
    }

    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .header__select {
        max-height: 150px;
        overflow-y: auto;
      }
    }

    &__filter {
      border-top: 1px solid #e4e6eb;
      padding: 24px 0;
    }

    &__text {
      margin-bottom: 0;
      color: #2e333b;
      font-size: 16px;
      font-weight: bold;
    }

    &__btn {
      height: 36px;
    }

    &__input {
      height: 36px;
    }

    &__input-margin {
      margin-right: 16px;
    }

    &__btn-margin {
      margin-right: 16px;
    }
  }
}
